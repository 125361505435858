import React from 'react'
import Emailus from './Emailus'
import { FaRegHandPointRight } from "react-icons/fa";


function Contact() {
  return (
    <div className='servlist grid  gap-0.5 sm:grid-cols-2 '>
      <div className='mx-2' >
      <h1 className='text-2xl text-black'>Contact us for plumbing services:</h1><br/>
      <p>At Joskam Plumbers, we’re here to handle all your plumbing needs with
         efficiency and expertise . Whether you’re dealing with a leaky faucet, a clogged 
         drain, or planning a major plumbing project, our team of skilled professionals 
         is ready to help.</p>
         <br></br>

         <div > 
            
            <ul className='flex-col font-medium '>
      <li > <FaRegHandPointRight /> Hot and cold water supply piping</li>
      <li ><FaRegHandPointRight />Drainage below and above ground</li>
      <li ><FaRegHandPointRight />Installation of  sanitary appliances</li>
      <li ><FaRegHandPointRight />Fixing of bathroom  accessories</li>
      <li ><FaRegHandPointRight />Installation of  solar water heater</li>
      <li > <FaRegHandPointRight />Installation of  gutters</li>
      <li > <FaRegHandPointRight />Pumps </li>
      <li ><FaRegHandPointRight />General  plumbing repairs</li>
    </ul>
        </div>

       </div>

       <div>
          <Emailus />
         </div>
    </div>
  )
}

export default Contact
