import React from 'react'

function About() {
  return (
    <>
    <div className='aboutus grid  gap-0.5 sm:grid-cols-2'>
      <div>
    <h2 className='custom-h2'>Welcom to JOSKAM Plumbers</h2>
    <p>
    Joskam Plumbers is a kenyan based bumbing company, where plumbing solutions meets excellence, 
    we believe in the power of plumbing to make a difference in people's lives. 
      Joskam Plumbers began with a simple idea: to provide reliable plumbing services with 
    a personal touch. Over the years, our dedication to quality craftsmanship and
     exceptional service has earned us the trust of countless homeowners and businesses 
     alike. We  serving both residential and commercial clients with the same dedication
      and attention to detail that set us apart from the beginning.
    </p>
    </div>
    <div>
    <img  src="https://joskam.co.ke/images/pipping.jpg" alt="bathtubpipping" className="w-300px h-200px object-cover rounded-md p-4 " />

    </div>
   </div>

    <div className='missionvision flex flex-col sm:flex-row'>
      <div className='mission'>
        <h2 className='custom-h2'>our mission</h2>
        <p>Joskam plumbers mission is to provide comprehensive plumbing services that
           exceed our customers' expectations. We strive to achieve this through expert
            knowledge, innovative solutions, and a dedication to excellence, ensuring that
            every customer receives the best possible service experience.</p>
        </div>

      <div className='ourvalue'>
        <h2 className='custom-h2'>ourvalue</h2>
        <p>
        Integrity: We uphold the highest standards of honesty and transparency in all
         our interactions. Our customers can trust us to provide fair and accurate 
         assessments and pricing.
         Quality: Excellence in every detail is our commitment. From the materials
          we use to the craftsmanship we deliver, quality is at the heart of what we do.
          Customer Satisfaction: Our customers are our top priority. We strive to exceed 
          expectations by providing exceptional service and ensuring every customer is
           completely satisfied with our work.
        </p>
        </div>

      <div className='whyus'>
        <h2 className='custom-h2'>why choose joskam plumbers</h2>
        <p>
        Experienced Professionals: Our team consists of licensed and experienced 
        plumbers who have the expertise to tackle even the most challenging plumbing
         problems.
         Personalized Solutions: Every home and business is unique, and we tailor our
          services to meet your specific requirements and budget.
          Prompt Service: We understand that plumbing emergencies can happen at any time.
           That's why we offer 24/7 emergency service to address your urgent needs.
           Guaranteed Satisfaction: Your satisfaction is guaranteed. If you're not 
           happy with our work for any reason, we'll make it right, no questions asked. 
        </p>
        </div>
      
    </div>
    </>
  )
}

export default About
