import React, {useState} from 'react'
import { BsChevronCompactLeft, BsChevronCompactRight } from "react-icons/bs";
import { RxDotFilled } from "react-icons/rx";
import HomeP from "./HomeP"

const Home = () => {
  const slider =[
    {url: 'https://joskam.co.ke/images/bathtub.jpg'},
    {url: 'https://joskam.co.ke/images/pipeing.jpg'},
    {url: 'https://joskam.co.ke/images/sink.jpg'},
  ]

  const [currentIndex, setCurrentIndex]= useState(0)

  const prevSlider = ()=>{
    const isFirstSlide = currentIndex === 0
    const newIndex = isFirstSlide ? slider.length - 1: currentIndex - 1
    setCurrentIndex(newIndex)
  }

  const nextSlide = ()=>{
    const islastSlide = currentIndex === slider.length - 1
    const newIndex = islastSlide ? 0 : currentIndex + 1
    setCurrentIndex(newIndex)
  }

  const moveToNextSlide = (slideIndex)=>{
    setCurrentIndex(slideIndex)
  }

  return (
    <div className='max-w-full h-[400px] w-full py-2 px-4 relative group'>
      <div className='w-full h-full rounded-2xl bg-center bg-cover duration-500' 
      style={{backgroundImage: `url(${slider[currentIndex].url})` }}>
      </div>

      <div className='hidden group-hover:block absolute top-[50%] -translate-x-0 trnslate-y-[-50%] left-5 text-2xl rounded-full text-white bg-orange-700'>
      <BsChevronCompactLeft  onClick={prevSlider}/>
      </div>

      <div className='hidden group-hover:block absolute top-[50%] -translate-x-0 trnslate-y-[-50%] right-5 text-2xl rounded-full text-white bg-orange-700'>
      <BsChevronCompactRight onClick={nextSlide}/>
      </div>

      <div className='flex top-4 justify-center py-2'>
        {
          slider.map((sliderItem, slideIndex)=>(
          <div 
          key={slideIndex}
          onClick={()=>moveToNextSlide(slideIndex)}
          className='text-2xl cursor-pointer'>
            <RxDotFilled />
          </div>
          ))
        }
      </div>

   


    </div>
  )
}
    
export default Home
