import React from 'react'
import { IoCallOutline } from "react-icons/io5";
import { TfiEmail } from "react-icons/tfi";
import { Link } from 'react-router-dom';

function Navibar() {
  return (
    <nav className='nav'>
      <img  src="/joskam-logo.png" alt="logo" className='w-10 h-10'/>

      <ul className='mainmenu '>
            <li> <Link to='/'>HOME </Link> </li>
            <li>  <Link to='/About'>ABOUT </Link> </li>
            <li> <Link to='/Services'>SERVICES</Link> </li>
            <li> <Link to='/Projects'>PROJECTS </Link> </li>
            <li> <Link to='/Contact'>CONTACT US </Link> </li>
          </ul>

        <span className='btngroup'>  

          <button className='normalbtn'>
        <IoCallOutline size={25}/> :+254 729 364 609
       
        </button>

          
        </span>      
      
    </nav>
  )
}

export default Navibar
