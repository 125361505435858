import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';


function Emailus() {
  const form = useRef()

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('service_vutkqsq', 'contact_form', form.current, {
        publicKey: 'bcT5tArLZUVSDKV19',
      })
      .then(
        () => {
          console.log('SUCCESS!');
        },
        (error) => {
          console.log('FAILED...', error.text);
        },
      );
     
  };
  
  return (
    <div className='flex flex-col items-center bg-gray-200 w-96 my-5'>
      <h2 className=' custom-h2 text-2xl text-blue-700 '>Contact Us</h2>
      <form ref={form} onClick={sendEmail} className='flex flex-col space-y-4  items-center' >
        <input type='text' placeholder='Full Name' name='user_name' className='border border-solid border-black w-48' required></input>
        <input type='email' placeholder='Your Email' name='user_email' className='border border-solid border-black w-48' required></input>
        <input type='text' placeholder='Subject' name='subject' className='border border-solid border-black w-48' required></input>
        <textarea name='message' cols={10} rows={10} placeholder='Enter your message here' className='border border-solid border-black w-64'></textarea>
        <button type='submit' className='normalbtn w-28 font-black text-lg' >Send</button>
      </form>
    </div>
  )
}

export default Emailus
