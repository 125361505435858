import React from 'react'
import ReactPlayer from 'react-player'
import { FaRegHandPointRight } from "react-icons/fa";

function HomeP() {
  return (
    <>
     <div>
        <h3 className='text-orange-500 text-1xl font-bold text-center py-2'>JOSKAM plumbers Your Trusted Partner in Plumbing Solutions</h3>   
     </div>

     <div className='vidands grid  gap-0.5 sm:grid-cols-2'>
        <div className='vid '>
            <ReactPlayer url='https://joskam.co.ke/images/video.mp4' controls='true' volume={0} muted='true'/>

        </div>

        <div className='servlist'> 
            <h2 className='custom-h2'>Our services includes:</h2>
            <ul className='flex flex-col font-medium '>
      <li > <FaRegHandPointRight /> Hot and cold water supply piping</li>
      <li ><FaRegHandPointRight />Drainage below and above ground</li>
      <li ><FaRegHandPointRight />Installation of  sanitary appliances</li>
      <li ><FaRegHandPointRight />Fixing of bathroom  accessories</li>
      <li ><FaRegHandPointRight />Installation of  solar water heater</li>
      <li > <FaRegHandPointRight />Installation of  gutters</li>
      <li > <FaRegHandPointRight />Pumps </li>
      <li ><FaRegHandPointRight />General  plumbing repairs</li>
    </ul>
        </div>


     </div>
    </>
  )
}

export default HomeP
