import Navibar from "./component/Navibar";
import "./index.css"
import {Route, Routes} from 'react-router-dom'
import Home from "./pages/Home";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Projects from "./pages/Projects";
import Services from "./pages/Services";
import Footer from "./component/Footer";
import HomeP from "./pages/HomeP";




function App() {
  return (
    <div className="App">
     <Navibar />
    
     <div>
      <Routes>
        <Route path="/" element={<Home />}/>
        <Route path="/About" element={<About />}/>
        <Route path="/Services" element={<Services />}/>
        <Route path="/Projects" element={<Projects />}/>
        <Route path="/Contact" element={<Contact />}/>

      </Routes>
      <HomeP />
      <About />
      <Services />
       <Contact />
       
      <Footer />

     </div>
     
    </div>

      );

     
}

export default App;
