import React from 'react'

function Services() {
  return (
    <div className='grid grid-cols-1 sm:grid-cols-2 '>
      <div className='servlist'>
      <img src='https://joskam.co.ke/images/Hot_cold.jpg' alt="Description of my image" />

        <h2 className='custom-h2'>Hot and cold water supply piping</h2>

        <p>Need reliable hot and cold water piping? Choose Wanka Plumbers! Our licensed 
          plumbers offer expert installation, repair, maintenance, leak detection, and
           emergency services. Quality workmanship and customer satisfaction are our
            priorities. Let us handle your plumbing needs!</p>

      </div>

      <div className='servlist'>
      <img src='https://joskam.co.ke/images/drainage.jpg' alt="Description of my image" />

        <h2 className='custom-h2'>Drainage below and above ground</h2>

        <p>We specialize in comprehensive drainage solutions for both above-ground 
          and below-ground plumbing. Our services include installing and maintaining
           pipes that remove wastewater from fixtures like sinks, toilets, and showers,
            ensuring it flows smoothly into the sewer or septic system. Additionally,
             we expertly manage the underground network of pipes that transport wastewater
              away from your building, preventing flooding and maintaining sanitary 
              conditions. Trust us to keep your drainage system efficient and reliable.</p>

        </div>

      <div className='servlist'>
      <img src='https://joskam.co.ke/images/sanitary.jpg' alt="Description of my image" />
        
        <h2 className='custom-h2'>Installation of  sanitary appliances</h2>

        <p>We specialize in the installation of sanitary appliances, 
          ensuring efficient and reliable connections for sinks, toilets,
           showers, and other fixtures. Our expert services guarantee proper
            functionality and hygiene for all your plumbing needs. Trust us 
            for professional and precise installations.</p>
        
        </div>
      <div className='servlist'>
      <img src='https://joskam.co.ke/images/bathroom_accessories.jpg' alt="Description of my image" />
        
        <h2 className='custom-h2'>Fixing of bathroom  accessories</h2>

        <p>We excel in installing bathroom accessories, offering expertise in setting 
          up fixtures such as towel racks, toilet paper holders, and shower shelves.
           Our meticulous installations ensure functionality and aesthetics, enhancing 
           your bathroom's usability and appearance. Trust us to add the finishing touches
            to your plumbing projects with precision and care.</p>
        </div>

      <div className='servlist'>
      <img src='https://joskam.co.ke/images/solar-heater.jpg' alt="Description of my image" />
        
        <h2 className='custom-h2'> Installation of  solar water heater</h2>

        <p>We specialize in the installation and upkeep of solar water heaters, providing 
          environmentally friendly and energy-efficient solutions for residential 
          and commercial properties. Our services encompass expert positioning and
           maintenance of solar panels and associated components, ensuring optimal 
           performance and durability. Count on us to seamlessly integrate solar 
           technology into your plumbing infrastructure, delivering reliable and 
           sustainable hot water solutions tailored to your needs.</p>
        
        </div>
      <div className='servlist'>
      <img src='https://joskam.co.ke/images/gutters.jpg' alt="Description of my image" />
        
        <h2 className='custom-h2'>Installation of  gutters</h2>

        <p>We offer comprehensive services for the installation of gutter accessories, 
          ensuring efficient drainage and protection for residential and commercial 
          properties. Our expertise covers the setup of gutter guards, downspouts,
           and drainage systems, safeguarding your building from water damage and 
           enhancing its structural integrity. Trust us to deliver precise and durable 
           installations that mitigate the risks of water-related issues, keeping your 
           property safe and secure.</p>
        
        </div>

      <div className='servlist'>
      <img src='https://joskam.co.ke/images/pump.jpeg' alt="Description of my image" />
        
        <h2 className='custom-h2'> Pumps</h2>

        <p>We specialize in water pump installation, providing expert services to 
          ensure efficient water distribution for residential, commercial, 
          and agricultural applications. Our skilled technicians handle every aspect
           of the installation process, from selecting the appropriate pump for your
           needs to ensuring proper placement and connection. Count on us for reliable
            and professional installations that optimize water flow and enhance the 
            performance of your plumbing system.</p>

        </div>

      <div className='servlist'>
      <img src='https://joskam.co.ke/images/tools.jpeg' alt="Description of my image" />


       <h2 className='custom-h2'>General plumbing repairs</h2> 

       <p>We provide expert plumbing repairs for residential, commercial, 
        and industrial needs. From leaky faucets to pipe repairs, our skilled
         technicians ensure prompt and reliable service to restore your plumbing 
         system's functionality. Trust us for efficient solutions and peace of mind.</p>
        </div>

    </div>
  )
}

export default Services
